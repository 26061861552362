<script>
  import feedbackGeneralApi from '@api/feedback_generals';

  export default {
    data: () => ({
      perPage: 10,
      totalCount: 0,
      page: 1,
      size: 10,
      status: 'O',
      keyword: '',
      pageSize: [
        { name: '10', value: 10, isActive: true },
        { name: '20', value: 20, isActive: false },
        { name: '50', value: 50, isActive: false },
        { name: '100', value: 100, isActive: false },
      ],
      dataSource: [],
      dataColumns: [
        { caption: 'Subject', field: 'feedback_subject' },
        { caption: 'Feedback Status', field: 'feedback_status' },
      ],
    }),
    watch: {
      page(v) {
        this.loadGeneralFeedback();
      },
      size(v) {
        this.loadGeneralFeedback();
      },
    },
    mounted() {
      this.loadGeneralFeedback();
    },
    methods: {
      handleEvent(event) {},
      loadGeneralFeedback() {
        const params = {
          sort: '-updated_at',
          page: { size: this.size, after: this.page },
          status: 'O',
        };

        if (this.keyword) {
          params.filter = this.keyword;
        }

        const filterExt = [`joborder_id=${this.$route.query.id}`];

        if (filterExt.length > 0) {
          params.filterExt = filterExt.join(',');
        }

        feedbackGeneralApi
          .list(params)
          .then(({ data, meta }) => {
            if (data != null) {
              this.dataSource = data;

              this.totalCount = meta.page.total;
            }
          })
          .catch((err) => {
            //
          })
          .finally(() => {
            //
          });
      },
      onSetPage(event) {
        for (let i = 0; i < this.pageSize.length; i++) {
          const page = this.pageSize[i];

          page.isActive = event.value === page.value;
        }

        this.size = event.value;
        this.perPage = event.value;
      },
    },
  };
</script>

<template>
  <span>
    <b-row>
      <b-col>
        <b-form-group>
          <DxDataGrid
            key-expr="id"
            :data-source="dataSource"
            :focused-row-enabled="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-borders="true"
            :row-alternation-enabled="true"
          >
            <DxEditing
              :allow-updating="false"
              :allow-deleting="false"
              :allow-adding="false"
              mode="row"
            />

            <DxPaging :page-size="totalCount" />

            <DxSearchPanel location="before" :visible="true" />
            <DxColumnChooser :enabled="true" />
            <DxColumnFixing :enabled="true" />
            <DxSorting mode="multiple" />
            <DxFilterRow :visible="true" />
            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="false" />
            <DxHeaderFilter :visible="true" />

            <DxToolbar>
              <DxItem location="before" name="columnChooserButton" />
              <DxItem location="before" name="searchPanel" />
              <DxItem name="groupPanel" />
            </DxToolbar>

            <DxColumn
              v-for="(item, index) in dataColumns"
              :key="index"
              :data-field="item.field"
              :caption="item.caption"
              :format="item.format"
              :data-type="item.dataType"
            />

            <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
            <template #masterDetailTemplate="{ data }">
              <b-card>
                <b-card-text>
                  <div class="">
                    <label for="">Description:</label>
                    <div style="text-align: justify; white-space: normal">
                      {{ data.data.feedback_description }}
                    </div>
                  </div>
                </b-card-text>
              </b-card>
            </template>
          </DxDataGrid>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-nav>
          <b-nav-item
            @click="onSetPage(page)"
            v-for="(page, index) in pageSize"
            :key="index"
          >
            <span :class="page.isActive ? 'text-success' : ''">
              {{ page.name }}
            </span>
          </b-nav-item>
        </b-nav>
      </b-col>
      <b-col>
        <b-pagination
          align="right"
          v-model="page"
          :total-rows="totalCount"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        />
      </b-col>
    </b-row>
  </span>
</template>
